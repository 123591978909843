import store from '@/store';

export interface IRentalObjectGroupList {
  Items: Array<IRentalObjectGroupList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface IRentalObjectGroupList_Item {
  Id: number|null;
  Name: string;
}

export default class RentalObjectGroupList implements IRentalObjectGroupList {
  Items: Array<IRentalObjectGroupList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: IRentalObjectGroupList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number): Promise<RentalObjectGroupList> {
    return await store.state.hyrma.client.RentalObjectGroup.List((page - 1) * itemsPerPage, itemsPerPage);
  }

  static async FetchAll(): Promise<RentalObjectGroupList> {
    return await store.state.hyrma.client.RentalObjectGroup.List();
  }
}

