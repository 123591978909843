import store from '@/store';

export interface ICustomerProjectEdit {
  Id?: number | null,
  CustomerId?: number | null,
  ProjectNumber?: string | null,
  Name?: string | null,
  Address?: string | null,
  Address2?: string | null,
  PostalCode?: string | null,
  PostalArea?: string | null,
  CountryCode?: string | null,
  Country?: string | null,
  ContactName?: string | null,
  Phone?: string | null
}

export default class CustomerProjectEdit implements ICustomerProjectEdit {
  Id?: number | null = null;
  CustomerId?: number | null = null;
  ProjectNumber?: string | null = null;
  Name?: string | null = null;
  Address?: string | null = null;
  Address2?: string | null = null;
  PostalCode?: string | null = null;
  PostalArea?: string | null = null;
  CountryCode?: string | null = null;
  Country?: string | null = null;
  ContactName?: string | null = null;
  Phone?: string | null = null;

  constructor(obj?: ICustomerProjectEdit) {
    Object.assign(this, obj);
  }

  async SaveUpdate(): Promise<CustomerProjectEdit> {
    const customerProjectEdit = await store.state.hyrma.client.CustomerProject.SaveUpdate(this);
    Object.assign(this, customerProjectEdit);
    return this;
  }
}
