import store from '@/store';

export interface IInventoryItemList {
  Items: Array<IInventoryItemList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface IInventoryItemList_Item {
  Id: number;
  Number?: string;
  ObjectId?:number;
  ObjectNumber?: string;
  Name?: string;
  Capacity?: string;
  Group?: string;
  RentalType?: string;
  InventoryType?: number|null;
  DefaultVatId:number;
  Free?: boolean;
  Unit?: string;
}

export default class InventoryItemList implements IInventoryItemList {
  Items: Array<IInventoryItemList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: IInventoryItemList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number, search?: string, depot?: string, group?: number|null): Promise<InventoryItemList> {
    return await store.state.hyrma.client.InventoryItem.List((page - 1) * itemsPerPage, itemsPerPage, search || undefined, depot, group || undefined);
  }
}

