import store from '@/store';

export interface ICustomerProjectList {
  Items: Array<ICustomerProjectList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface ICustomerProjectList_Item {
  Id: number;
  CustomerNumber: string;
  CustomerName: string;
  ProjectNumber: string;
  ProjectName: string;
  Address: string;
  Address2: string;
  PostalCode: string;
  PostalArea: string;
}

export default class CustomerProjectList implements ICustomerProjectList {
  Items: Array<ICustomerProjectList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: ICustomerProjectList) {
    Object.assign(this, obj);
  }

  static async Fetch(customerId: number, page: number, itemsPerPage: number, search?: string, sortBy: string = 'nam', sortAsc: boolean = true): Promise<CustomerProjectList> {
    return await store.state.hyrma.client.CustomerProject.List(customerId, (page - 1) * itemsPerPage, itemsPerPage, search, sortBy, sortAsc);
  }
}

