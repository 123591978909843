import { AxiosInstance } from 'axios';
import CurrencyList, { ICurrencyList_Item } from '@/modules/Currency/List';

export default class CurrencyApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(): Promise<CurrencyList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/Currency/List'
    );

    const items = res.data.data.currencies.map((c: any) => ({
      Id: c.id,
      CurrencyCode: c.currencyCode,
      Rounding: c.rounding,
    }));

    return new CurrencyList({
      CompanyCurrencyId: res.data.data.companyCurrencyId,
      Items: items,
    });
  }
}