import store from '@/store';

export interface IStockItemList {
  Items: Array<IStockItemList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface IStockItemList_Item {
  Id: number;
  ItemNumber?: string;
  Name?: string;
  Group?: string;
  Unit?: string;
  UnitPrice?: number;
  DefaultVatId?: number;
}

export default class StockItemList implements IStockItemList {
  Items: Array<IStockItemList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: IStockItemList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number, search?: string, depot?: string, group?: number|null): Promise<StockItemList> {
    return await store.state.hyrma.client.StockItem.List((page - 1) * itemsPerPage, itemsPerPage, search || undefined, depot, group || undefined);
  }
}

