import { AxiosInstance } from 'axios';
import TextItemList, { ITextItemList_Item } from '@/modules/TextItem/List';

export default class TextItemApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(offset: number = 0, limit?: number, search?: string, sortAsc: boolean = true): Promise<TextItemList | null> {
    const res = await this._client.get(
      '/Hyrma/TextItem/Edit_TextItemList_SlickGrid',
      { params: { search, limit, offset, sortAsc } }
    );

    const items = res.data.data.map((ti: any) => (
      {
        Id: ti.id,
        Code: ti.cod,
        Text: ti.tex,
      } as ITextItemList_Item)
    );

    return new TextItemList({
      Items: items,
      Count: res.data.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}