import store from '@/store';

export interface IDepotList {
  Items: Array<IDepotList_Item>;
}

export interface IDepotList_Item {
  Id: number;
  Name: string;
  ShortName: string;
}

export default class DepotList implements IDepotList {
  Items: Array<IDepotList_Item> = [];

  constructor(obj?: IDepotList) {
    Object.assign(this, obj);
  }

  static async FetchAll(): Promise<DepotList> {
    return await store.state.hyrma.client.Depot.List();
  }
}

