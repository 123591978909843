import store from '@/store';

export interface ICustomerList {
  Items: Array<ICustomerList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface ICustomerList_Item {
  Id: number;
  Number: string;
  Name: string;
  PostalArea?: string;
  OverdueAmount?: number;
}

export default class CustomerList implements ICustomerList {
  Items: Array<ICustomerList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: ICustomerList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number, search?: string, sortBy: string = 'nam', sortAsc: boolean = true): Promise<CustomerList> {
    return await store.state.hyrma.client.Customer.List((page - 1) * itemsPerPage, itemsPerPage, search, sortBy, sortAsc);
  }
}

