import { AxiosInstance } from 'axios';
import InventoryItemList, { IInventoryItemList_Item } from '@/modules/InventoryItem/List';

export default class InventoryItemApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(offset: number = 0, limit?: number, search?: string, depot?: number, group?: number): Promise<InventoryItemList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/InventoryItem/List',
      { params: { q: search, depot, group, limit, offset } }
    );

    const items = res.data.data.items.map((ii: any) => (
      {
        Id: ii.id,
        Number: ii.inu,
        ObjectId: ii.rid,
        ObjectNumber: ii.rnu,
        Name: ii.rna,
        Capacity: ii.cap,
        Group: ii.grp,
        RentalType: ii.rpd,
        InventoryType: ii.ity,
        DefaultVatId: ii.vat,
        Free: ii.fre,
        Unit: ii.uni,
      } as IInventoryItemList_Item)
    );

    return new InventoryItemList({
      Items: items,
      Count: res.data.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}