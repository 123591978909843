import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import vuexI18n from 'vuex-i18n';
import HyrmaApi from './api/HyrmaApi';
import User from './modules/User';

Vue.use(Vuex);

const hyrmaApiClient = new HyrmaApi(process.env.VUE_APP_HYRMA_BASE_URL as string);

const store = new Vuex.Store({
  state: {
    config: {
      hyrmaBaseUrl: process.env.VUE_APP_HYRMA_BASE_URL,
    },
    hyrma: {
      client: hyrmaApiClient as HyrmaApi,
      user: null,
    },
  },
  mutations: {
    SET_HYRMA_USER(state: any, user: User) {
      state.hyrma.user = user;

      const authHash = user ? user.AuthHash : '';
      hyrmaApiClient.SetAuthorizationHeader(authHash);
    },
  },
  actions: {
    setHyrmaUser: ({ commit }: any, user: User) => {
      commit('SET_HYRMA_USER', user);
    },
  },
  strict: process.env.NODE_ENV !== 'production',
});

Vue.use(vuexI18n.plugin, store);
export default store;
