import store from '@/store';

export interface IPaymentTermList {
  Items: Array<IPaymentTermList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface IPaymentTermList_Item {
  Id: number;
  Name: string;
  Code: string;
  NumDays: number;
}

export default class PaymentTermList implements IPaymentTermList {
  Items: Array<IPaymentTermList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: IPaymentTermList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number, sortBy: string = 'nm', sortAsc: boolean = true): Promise<PaymentTermList> {
    return await store.state.hyrma.client.PaymentTerm.List(sortBy, sortAsc, (page - 1) * itemsPerPage, itemsPerPage);
  }

  static async FetchAll(sortBy: string = 'nm', sortAsc: boolean = true): Promise<PaymentTermList> {
    return await store.state.hyrma.client.PaymentTerm.List(sortBy, sortAsc);
  }
}

