import store from '@/store';

export interface IVatList {
  Items: Array<IVatList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface IVatList_Item {
  Id: number;
  Name: string;
  Code: string;
  Percent: number;
}

export default class VatList implements IVatList {
  Items: Array<IVatList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: IVatList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number, sortBy: string = 'nm', sortAsc: boolean = true): Promise<VatList> {
    return await store.state.hyrma.client.Vat.List(sortBy, sortAsc, (page - 1) * itemsPerPage, itemsPerPage);
  }

  static async FetchAll(sortBy: string = 'nm', sortAsc: boolean = true): Promise<VatList> {
    return await store.state.hyrma.client.Vat.List(sortBy, sortAsc);
  }
}

