import store from '@/store';
import moment from 'moment';

export interface ILeaseItemEditStockItem {
  Id?: number;
  LeaseId: number;
  StockItemId: number | null;
  StockItemNumber: string;
  DiscountPercent: number;
  Quantity: number;
  Date: string;
  Name: string;
  Unit: string;
  UnitPrice: number;
  VatId: number;
  CustomerId?: number;
  CustomerProjectId?: number | null;
  ReferenceUserId?: number | null;
  ReferenceUserText: string;
}

export default class LeaseItemEditStockItem implements ILeaseItemEditStockItem {
  Id?: number;
  LeaseId: number = -1;
  StockItemId: number | null = null;
  StockItemNumber: string = '';
  DiscountPercent: number = 0;
  Quantity: number = 1;
  Date: string = moment().format('YYYY-MM-DD');
  Name: string = '';
  Unit: string = '';
  UnitPrice: number = 0;
  VatId: number = -1;
  CustomerId?: number;
  CustomerProjectId?: number | null;
  ReferenceUserId?: number | null = null;
  ReferenceUserText: string = '';

  constructor(obj?: ILeaseItemEditStockItem) {
    Object.assign(this, obj);
  }

  static async Find(id: number): Promise<LeaseItemEditStockItem> {
    return await store.state.hyrma.client.LeaseItem.EditStockItem(id);
  }

  async Save(): Promise<LeaseItemEditStockItem> {
    const leaseItem = await store.state.hyrma.client.LeaseItem.SaveStockItem(this);
    Object.assign(this, leaseItem);
    return this;
  }
}

