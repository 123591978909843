






















import { Component, Vue } from 'vue-property-decorator';
import User from '@/modules/User'
import UserRole from '@/enums/UserRole';
@Component
export default class Index extends Vue {
  accessStocktaking = false;
  accessLeases = false;
  userRole: UserRole | null = null;

  async mounted() {
    this.userRole          = await User.Role();
    this.accessStocktaking = await User.Can('AccessStocktaking');
    this.accessLeases      = await User.Can('AccessLeases');
  }
}
