import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';
import vueDebounce from 'vue-debounce';
import moment from 'moment';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { QrcodeStream } from 'vue-qrcode-reader';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faAngleDoubleLeft,
  faCalculator,
  faCheck,
  faCube,
  faCubes,
  faDesktop,
  faEdit,
  faFileAlt,
  faFileUpload,
  faFolderOpen,
  faFont,
  faList,
  faBars,
  faNewspaper,
  faPlusSquare,
  faQrcode,
  faSignOutAlt,
  faSleigh,
  faSpinner,
  faTag,
  faThList,
  faTimes,
  faTrash,
  faTruck,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAngleDoubleLeft,
  faCalculator,
  faCheck,
  faCube,
  faCubes,
  faDesktop,
  faEdit, 
  faFileAlt,
  faFileUpload,
  faFolderOpen,
  faFont,
  faList,
  faBars,
  faNewspaper,
  faPlusSquare,
  faQrcode,
  faSignOutAlt,
  faSleigh,
  faSpinner,
  faTag,
  faThList,
  faTimes,
  faTrash,
  faTruck,
  faUser,
);

Vue.filter('formatDate',     (value: string, format: string | undefined = 'YYYY-MM-DD') => value ? moment(value).format(format) : value);
Vue.filter('formatDateTime', (value: string, format: string | undefined = 'YYYY-MM-DD hh:mm') => value ? moment(value).format(format) : value);

Vue.component('v-select',                vSelect);
Vue.component('font-awesome-icon',       FontAwesomeIcon);
Vue.component('page-header',             () => import('./components/PageHeader.vue'));
Vue.component('page-footer',             () => import('./components/PageFooter.vue'));
Vue.component('loading-indicator',       () => import('./components/LoadingIndicator.vue'));
Vue.component('lease-edit',              () => import('./components/Lease/Edit.vue'));
Vue.component('customer-select',         () => import('./components/CustomerSelect.vue'));
Vue.component('customer-project-select', () => import('./components/CustomerProjectSelect.vue'));
Vue.component('inventory-item-select',   () => import('./components/InventoryItemSelect.vue'));
Vue.component('stock-item-select',       () => import('./components/StockItemSelect.vue'));
Vue.component('text-item-select',        () => import('./components/TextItemSelect.vue'));
Vue.component('qrcode-stream',           QrcodeStream);
Vue.component('qr-scan-button',          () => import('./components/QrScanButton.vue'));
Vue.component('qr-scan-view',            () => import('./components/QrScanView.vue'));
Vue.component('table-column-selector',   () => import('./components/TableColumnSelector.vue'));

Vue.use(vueDebounce, { defaultTime:'700ms' })
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
