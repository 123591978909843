import Vue from 'vue';
import store from '../store';

export interface ITranslation {
  [propName: string]: string;
}

export default class Translation implements ITranslation {
  [propName: string]: string;

  constructor(obj?: ITranslation) {
    Object.assign(this, obj);
    for (const key in this) {
      const value = this[key];
      const capitalizedKey = Translation.Capitalize(key);
      if (key !== capitalizedKey) {
        this[capitalizedKey] = Translation.Capitalize(value);
      }
    }
  }

  static async Load(languageCode: string): Promise<void> {
    const ts: Translation = await store.state.hyrma.client.Translation.Find(languageCode);

    Vue.i18n.add(languageCode, ts);
    Vue.i18n.set(languageCode);
  }

  static Capitalize(s: string): string {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
}
