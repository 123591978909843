import { AxiosInstance } from 'axios';
import PaymentTermList, { IPaymentTermList_Item } from '@/modules/PaymentTerm/List';

export default class PaymentTermApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(sortBy?: string, sortAsc: boolean = true, offset: number = 0, limit?: number): Promise<PaymentTermList | null> {
    const res = await this._client.get(
      '/Hyrma/PaymentTerm/Edit_Terms_SlickGrid',
      { params: { sortBy, sortAsc, limit, offset } }
    );

    // TODO: Error handling

    const items = res.data.data.map((pt: any) => (
      {
        Id: pt.id,
        Name: pt.nm,
        Code: pt.cd,
        NumDays: pt.nd,
      } as IPaymentTermList_Item
    ));

    return new PaymentTermList({
      Items: items,
      Count: res.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}