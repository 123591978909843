import store from '@/store';
import moment from 'moment';
import TransportStatus from '@/enums/TransportStatus';

export interface ILeaseItemEditRentalObject {
  Id?: number;
  LeaseId: number;
  Number: string;
  InventoryItemId?: number | null;
  InventoryItemNumber?: string;
  Name?: string;
  Quantity: number;
  RentedAmount: number | null;
  RentalObjectId?: number | null;
  RentalType: string;
  Unit: string;
  UnitPrice: number;
  DiscountPercent: number;
  RentedStart?: string | null;
  RentedFrom: string;
  RentedFromTime?: string;
  RentedTo?: string;
  RentedToTime?: string;
  DeliveryStatus?: TransportStatus | null;
  DeliveryComment?: string;
  DeliveryDriverUserId?: number | null;
  DeliveryDriverUserText: string;
  PickupStatus?: TransportStatus | null;
  PickupComment?: string;
  PickupDriverUserId?: number | null;
  PickupDriverUserText: string;
  RentalTypes?: Array<any>;
  VatId: number;
  IsDiscounted: boolean;
}

export default class LeaseItemEditRentalObject implements ILeaseItemEditRentalObject {
  Id?: number;
  LeaseId: number = -1;
  Number: string = '';
  InventoryItemId: number | null = null;
  InventoryItemNumber: string = '';
  InventoryType: number | null = null;
  Name: string = '';
  Quantity: number = 1;
  RentedAmount: number | null = null;
  RentalObjectId: number | null = null;
  RentalType: string = '';
  UnitPrice: number = 0;
  Unit: string = '';
  DiscountPercent: number = 0;
  RentedStart: string | null = null;
  RentedFrom: string = moment().format('YYYY-MM-DD');
  RentedFromTime?: string = moment().seconds(0).format('HH:mm:ss');
  RentedTo?: string;
  RentedToTime?: string;
  DeliveryStatus?: TransportStatus | null = null;
  DeliveryComment?: string;
  DeliveryDriverUserId?: number | null = null;
  DeliveryDriverUserText: string = '';
  PickupStatus?: TransportStatus | null = null;
  PickupComment?: string;
  PickupDriverUserId?: number | null = null;
  PickupDriverUserText: string = '';
  ReferenceUserId?: number | null = null;
  ReferenceUserText: string = '';
  RentalTypes?: Array<any> = [];
  TransportStatuses: Array<any> = [
    { Text: '', Value: null },
    { Text: TransportStatus[TransportStatus.Pending], Value: TransportStatus.Pending },
    { Text: TransportStatus[TransportStatus.Delivered], Value: TransportStatus.Delivered }
  ]
  VatId: number = -1;
  IsDiscounted: boolean = false;

  constructor(obj?: ILeaseItemEditRentalObject) {
    Object.assign(this, obj);
  }

  static async Find(id: number): Promise<LeaseItemEditRentalObject> {
    return await store.state.hyrma.client.LeaseItem.EditRentalObject(id);
  }

  async Save(): Promise<LeaseItemEditRentalObject> {
    const leaseItem = await store.state.hyrma.client.LeaseItem.SaveRentalObject(this);
    Object.assign(this, leaseItem);
    return this;
  }
}

