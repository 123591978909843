import { AxiosInstance } from 'axios';
import DepotList, { IDepotList_Item } from '@/modules/Depot/List';

export default class DepotApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(): Promise<DepotList | null> {
    const res = await this._client.get('/HyrmaMobile/Depot/List');

    const items = res.data.data.map((d: any) => (
      {
        Id: d.id,
        Name: d.na,
        ShortName: d.sn,
      } as IDepotList_Item)
    );

    return new DepotList({
      Items: items
    });
  }
}