import store from '@/store';

export interface IStockItemGroupList {
  Items: Array<IStockItemGroupList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface IStockItemGroupList_Item {
  Id: number|null;
  Name: string;
}

export default class StockItemGroupList implements IStockItemGroupList {
  Items: Array<IStockItemGroupList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: IStockItemGroupList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number): Promise<StockItemGroupList> {
    return await store.state.hyrma.client.StockItemGroup.List((page - 1) * itemsPerPage, itemsPerPage);
  }

  static async FetchAll(): Promise<StockItemGroupList> {
    return await store.state.hyrma.client.StockItemGroup.List();
  }
}

