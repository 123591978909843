import { AxiosInstance } from 'axios';
import LeaseEdit from '@/modules/Lease/Edit';
import LeaseList, { ILeaseList_Item } from '@/modules/Lease/List';

export default class LeaseApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(offset: number, limit: number, depotId?: number | null, searchHead?: string, searchContents?: string): Promise<LeaseList | null> {
    const params = {
      depot: depotId,
      search: searchHead,
      search2: searchContents,
      limit,
      offset,
    };

    const res = await this._client.get(
      '/Hyrma/Lease/List_SlickGrid',
      { params }
    );

    // TODO: Error handling
  
    const items = res.data.data.map((l: any) => (
      {
        LeaseId:         l.id,
        LeaseNumber:     l.nr,
        CustomerNumber:  l.cm,
        CustomerName:    l.cn,
        CustomerType:    l.ct,
        CustomerAccount: l.ca,
        PostalArea:      l.pa,
        CreatedAt:       l.cr,
      } as ILeaseList_Item)
    );

    return new LeaseList({
      Items: items,
      Count: res.data.count,
      Page: Math.floor((offset / limit)) + 1,
      ItemsPerPage: limit,
    });
  }

  async Edit(id: number): Promise<LeaseEdit | null> {
    const res = await this._client.get(
      '/HyrmaMobile/Lease/Edit',
      { params: { id } }
    );

    // TODO: Error handling

    return new LeaseEdit(res.data.data);
  }

  async Save(leaseEdit: LeaseEdit): Promise<LeaseEdit | null> {
    const res = await this._client.post(
      '/Mobile/Lease/Edit',
      leaseEdit
    );

    // TODO: Error handling

    return new LeaseEdit(res.data.data);
  }
}