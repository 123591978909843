import moment from 'moment';
import store from '@/store';

export interface ILeaseEdit {
  Id?: number | null;
  LeaseNumber: string;

  CustomerId: number | null;
  CustomerName: string;
  CustomerNumber: string;
  CustomerAddress: string;
  CustomerAddress2: string;
  CustomerPostalCode: string;
  CustomerPostalArea: string;
  CustomerCountryCode: string;
  CustomerCountry: string;
  OrgNo: string;
  VatNo: string;
  CustomerTelephone: string;

  CurrencyId: number | null;
  Currencies: Array<any>;

  Countries: Array<any>;

  CustomerProjectId: number | null;
  CustomerAccount: string;
  DeliveryName: string;
  DeliveryAddress: string;
  DeliveryAddress2: string;
  DeliveryPostalCode: string;
  DeliveryPostalArea: string;
  DeliveryContactName: string;
  DeliveryTelephone: string;

  DepotId: number | null;
  Depots: Array<any>;

  IsCreated?: boolean;
  IsUpdated?: boolean;

  IsContractCustomer?: boolean;
  IsEdiCustomer?: boolean;
  IsVatReversed?: boolean;

  RequireCustomerAccount?: boolean;
  MarkedForBilling?: boolean;

  PaymentTermId: number | null;
  PaymentTerms: Array<any>;

  ReferenceUserId?: number | null;
  ReferenceUserText: string;

  CustomerReference: string;

  RentalInsurancePercent?: number;

  CreateStatus?: number;
  CreatedAt?: Date | string;
  CanEdit?: boolean;

  NewItemReferenceUserId?: number | null;
  NewItemReferenceUserText: string;
  LeaseItemReferenceUserSuggestions: Array<any>;
}

export default class LeaseEdit implements ILeaseEdit {
  Id?: number | null = null;
  LeaseNumber: string = '';

  CustomerId: number | null = null;
  CustomerName: string = '';
  CustomerNumber: string = '';
  CustomerAddress: string = '';
  CustomerAddress2: string = '';
  CustomerPostalCode: string = '';
  CustomerPostalArea: string = '';
  CustomerCountryCode: string = '';
  CustomerCountry: string = '';
  OrgNo: string = '';
  VatNo: string = '';
  CustomerTelephone: string = '';

  CurrencyId: number | null = null;
  Currencies: Array<any> = [];

  CustomerProjectId: number | null = null;
  CustomerAccount: string = '';
  DeliveryName: string = '';
  DeliveryAddress: string = '';
  DeliveryAddress2: string = '';
  DeliveryPostalCode: string = '';
  DeliveryPostalArea: string = '';
  DeliveryCountryCode: string = '';
  DeliveryCountry: string = '';
  DeliveryContactName: string = '';
  DeliveryTelephone: string = '';

  DepotId: number | null = null;
  Depots: Array<any> = [];

  IsCreated?: boolean;
  IsUpdated?: boolean;

  IsContractCustomer?: boolean;
  IsEdiCustomer?: boolean;
  IsVatReversed?: boolean;

  RequireCustomerAccount?: boolean;
  MarkedForBilling?: boolean;

  PaymentTermId: number | null = null;
  PaymentTerms: Array<any> = [];

  ReferenceUserId?: number | null = null;
  ReferenceUserText: string = '';

  CustomerReference: string = '';

  RentalInsurancePercent?: number;

  CreateStatus?: number;
  CreatedAt?: Date | string;
  CanEdit?: boolean;

  Countries: Array<any> = [];

  NewItemReferenceUserId?: number | null = null;
  NewItemReferenceUserText: string = '';
  LeaseItemReferenceUserSuggestions: Array<any> = [];

  SetCountriesList(c: Array<any>) {
    this.Countries = c.map((co: any) => ({ Text: `${co.Value} - ${co.Text}`, Name: co.Text, Value: co.Value }));
  }

  constructor(obj?: ILeaseEdit) {
    Object.assign(this, obj);

    this.CreatedAt = moment(this.CreatedAt).toDate();
  }

  async Save(): Promise<LeaseEdit> {
    const leaseEdit = await store.state.hyrma.client.Lease.Save(this);
    Object.assign(this, leaseEdit);
    return this;
  }

  static async Find(id: number): Promise<LeaseEdit | null> {
    return await store.state.hyrma.client.Lease.Edit(id);
  }
}
