import store from '@/store';

export interface ICustomerInfo {
  Id                            : number;
  CustomerAccount               : string;
  Address                       : string;
  Address2                      : string;
  CountryCode                   : string;
  Country                       : string;
  CurrencyCode                  : string;
  CustomerNumber                : string;
  CustomerType                  : number;
  CustomerReference             : string;
  DeliveryAddress               : string;
  DeliveryAddress2              : string;
  DeliveryName                  : string;
  DeliveryPostalArea            : string;
  DeliveryPostalCode            : string;
  DeliveryCountryCode           : string;
  DeliveryCountry               : string;
  EdiType                       : number;
  EdiTypeName                   : string;
  IsEdiCustomer                 : boolean;
  IsContractCustomer            : boolean;
  EmailAddress                  : string;
  InsurancePercent              : number;
  RentalDiscountPercent         : number;
  SalesDiscountPercent          : number;
  RentalBonusPercent            : number;
  RentalBonusCalculationType    : number;
  RentalBonusLadderStartAmount  : number;
  RentalBonusLadderNumSteps     : number;
  RentalBonusLadderStepAmount   : number;
  RentalBonusLadderStepPercent  : number;
  SalesBonusPercent             : number;
  Name                          : string;
  OrgNo                         : string;
  PaymentTerm                   : number;
  PostalCode                    : string;
  PostalArea                    : string;
  Telephone                     : string;
  VatNo                         : string;
  GlnNo                         : string;
  CustomerReport                : string;
  RentalReportEmail             : string;
  IsBlocked                     : boolean;
  AutoShowReport                : boolean;
  RequireLeaseCustomerAccount   : boolean;
  RequireLeaseCustomerReference : boolean;
  RequireLeasePurchaser         : boolean;
  CreditAmount                  : number;
  AccountsReceivable            : number;
  OverdueAmount                 : number;
}

export default class CustomerInfo implements ICustomerInfo {
  Id                            : number = 0;
  CustomerAccount               : string = '';
  Address                       : string = '';
  Address2                      : string = '';
  CountryCode                   : string = '';
  Country                       : string = '';
  CurrencyCode                  : string = '';
  CustomerNumber                : string = '';
  CustomerType                  : number = 0;
  CustomerReference             : string = '';
  DeliveryAddress               : string = '';
  DeliveryAddress2              : string = '';
  DeliveryName                  : string = '';
  DeliveryPostalArea            : string = '';
  DeliveryPostalCode            : string = '';
  DeliveryCountryCode           : string = '';
  DeliveryCountry               : string = '';
  EdiType                       : number = 0;
  EdiTypeName                   : string = '';
  IsEdiCustomer                 : boolean = false;
  IsContractCustomer            : boolean = false;
  EmailAddress                  : string = '';
  InsurancePercent              : number = 0;
  RentalDiscountPercent         : number = 0;
  SalesDiscountPercent          : number = 0;
  RentalBonusPercent            : number = 0;
  RentalBonusCalculationType    : number = 0;
  RentalBonusLadderStartAmount  : number = 0;
  RentalBonusLadderNumSteps     : number = 0;
  RentalBonusLadderStepAmount   : number = 0;
  RentalBonusLadderStepPercent  : number = 0;
  SalesBonusPercent             : number = 0;
  Name                          : string = '';
  OrgNo                         : string = '';
  PaymentTerm                   : number = 0;
  PostalCode                    : string = '';
  PostalArea                    : string = '';
  Telephone                     : string = '';
  VatNo                         : string = '';
  GlnNo                         : string = '';
  CustomerReport                : string = '';
  RentalReportEmail             : string = '';
  IsBlocked                     : boolean = false;
  AutoShowReport                : boolean = false;
  RequireLeaseCustomerAccount   : boolean = false;
  RequireLeaseCustomerReference : boolean = false;
  RequireLeasePurchaser         : boolean = false;
  CreditAmount                  : number = 0;
  AccountsReceivable            : number = 0;
  OverdueAmount                 : number = 0;

  constructor(obj?: CustomerInfo) {
    Object.assign(this, obj);
  }

  static async Find(id: number): Promise<CustomerInfo | null> {
    return await store.state.hyrma.client.Customer.JsonInfo(id);
  }
}
