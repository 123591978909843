import store from '@/store';

export interface ICountryList {
  CompanyCountryCode: string;
  Items: Array<ICountryList_Item>;
}

export interface ICountryList_Item {
  TwoLetterCode: string;
  ThreeLetterCode: string;
  Name: string;
  NumericCode: string;
}

export default class CountryList {
  CompanyCountryCode: string | null = null;
  Items: Array<ICountryList_Item> = [];

  constructor(obj?: ICountryList) {
    Object.assign(this, obj);
  }

  static async FetchAll(): Promise<CountryList> {
    return await store.state.hyrma.client.Country.List();
  }
}

