import { AxiosInstance } from 'axios';
import VatList, { IVatList_Item } from '@/modules/Vat/List';

export default class VatApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(sortBy?: string, sortAsc: boolean = true, offset: number = 0, limit?: number): Promise<VatList | null> {
    const res = await this._client.get(
      '/Hyrma/Vat/Edit_VatList_SlickGrid',
      { params: { sortBy, sortAsc, limit, offset } }
    );

    const items = res.data.data.map((v: any) => (
      {
        Id: v.id,
        Name: v.nm,
        Code: v.cd,
        Percent: v.pt,
      } as IVatList_Item
    ));

    return new VatList({
      Items: items,
      Count: res.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}