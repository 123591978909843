import Vue from 'vue';
import Router from 'vue-router';
import Index from './views/Index.vue'; // Load statically
import Login from './views/Login.vue'; // Load statically

import User from './modules/User';
import store from './store';

Vue.use(Router);

const router = new Router({
  //mode: 'history',
  routes: [
    { path: '/', component: Index },
    { path: '/Login', component: Login },
    {
      path: '/Lease',
      component: () => import(/* webpackChunkName: "lease" */ './views/Lease/Index.vue'),
    },
    {
      path: '/Lease/New',
      component: () => import(/* webpackChunkName: "lease" */ './views/Lease/Edit.vue'),
    },
    {
      path: '/Lease/:leaseId/Edit',
      component: () => import(/* webpackChunkName: "lease" */ './views/Lease/Edit.vue'),
    },
    {
      path: '/Lease/:leaseId/Files',
      component: () => import(/* webpackChunkName: "lease" */ './views/Lease/Files.vue'),
    },
    {
      path: '/Lease/:leaseId/Items',
      component: () => import(/* webpackChunkName: "lease" */ './views/Lease/Items.vue'),
    },
    {
      path: '/Lease/:leaseId/AddRentalObject',
      component: () => import(/* webpackChunkName: "leaseitem" */ './views/LeaseItem/EditRentalObject.vue'),
      props: {
        isEdit: false,
      }
    },
    {
      path: '/Lease/:leaseId/EditRentalObject/:leaseItemId',
      component: () => import(/* webpackChunkName: "leaseitem" */ './views/LeaseItem/EditRentalObject.vue'),
      props: {
        isEdit: true,
      }
    },
    {
      path: '/Lease/:leaseId/AddStockItem',
      component: () => import(/* webpackChunkName: "leaseitem" */ './views/LeaseItem/EditStockItem.vue'),
      props: {
        isEdit: false,
      }
    },
    {
      path: '/Lease/:leaseId/EditStockItem/:leaseItemId',
      component: () => import(/* webpackChunkName: "leaseitem" */ './views/LeaseItem/EditStockItem.vue'),
      props: {
        isEdit: true,
      }
    },
    {
      path: '/Lease/:leaseId/AddTextItem',
      component: () => import(/* webpackChunkName: "leaseitem" */ './views/LeaseItem/EditTextItem.vue'),
      props: {
        isEdit: false,
      }
    },
    {
      path: '/Lease/:leaseId/EditTextItem/:leaseItemId',
      component: () => import(/* webpackChunkName: "leaseitem" */ './views/LeaseItem/EditTextItem.vue'),
      props: {
        isEdit: true,
      }
    },
    {
      path: '/Stocktaking',
      component: () => import(/* webpackChunkName: "stocktaking" */ './views/Stocktaking/Index.vue'),
    },
    {
      path: '/RentalObjectStocktaking/:id/DiffList',
      component: () => import(/* webpackChunkName: "stocktaking" */ './views/RentalObjectStocktaking/DiffList.vue'),
    },
    {
      path: '/RentalObjectStocktaking/:id/IndividualItems',
      component: () => import(/* webpackChunkName: "stocktaking" */ './views/RentalObjectStocktaking/IndividualItems.vue'),
    },
    {
      path: '/RentalObjectStocktaking/:id/QuantityItems',
      component: () => import(/* webpackChunkName: "stocktaking" */ './views/RentalObjectStocktaking/QuantityItems.vue'),
    },
    {
      path: '/RentalObjectStocktaking',
      component: () => import(/* webpackChunkName: "stocktaking" */ './views/RentalObjectStocktaking/Index.vue'),
    },
    {
      path: '/RentalObjectStocktaking/:id/SelectDepot',
      component: () => import(/* webpackChunkName: "stocktaking" */ './views/RentalObjectStocktaking/SelectDepot.vue'),
    },
    {
      path: '/RentalObjectStocktaking/:id/SelectInventoryType',
      component: () => import(/* webpackChunkName: "stocktaking" */ './views/RentalObjectStocktaking/SelectInventoryType.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.path === '/Login')
    return next();

  if (store.state.hyrma.user === null) {
    if (User.IsStored()) {
      try {
        await User.LoginStoredUser();
        return next();
      } catch (_) {}
    }
    
    return next('/Login');
  }

  return next();
});

export default router;