import { AxiosInstance } from 'axios';
import User, { IUser, IUser_Depot } from '@/modules/User';
import UserRole from '@/enums/UserRole';

export default class UserApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async Can(feature: string): Promise<boolean | null> {
    const res = await this._client.get('/HyrmaMobile/HyrmaUser/Can', { params: { feature } });

    return !!res.data.data;
  }

  async Info() : Promise<User | null> {
    const res = await this._client.get('/HyrmaMobile/HyrmaUser/Info');

    if (res.data.err)
      throw res.data.err;

    return this._createUserFromResponse(res.data.data);
  }

  async Role() : Promise<UserRole | null> {
    const res = await this._client.get('/HyrmaMobile/HyrmaUser/Role');

    if (res.data.err)
      throw res.data.err;

    return res.data.data.role;
  }

  async Depot(): Promise<IUser_Depot | null> {
    const res = await this._client.get('/HyrmaMobile/HyrmaUser/Depot');

    if (res.data.err)
      throw res.data.err;
    
    if (res.data.data.id == null)
      return null;

    return { 
      Id: res.data.data.id,
      ShortName: res.data.data.shortName,
      Name: res.data.data.name
    };
  }

  async QuickLogin(key: string): Promise<User | null> {
    const res = await this._client.post('/Api/User/QuickLogin', { key });

    if (res.data.err)
      throw res.data.err;

    return this._createUserFromResponse(res.data.data);
  }

  async Login(username: string, password: string): Promise<User | null> {
    const res = await this._client.post('/Api/User/Login', { username, password });

    if (res.data.err)
      throw res.data.err;
  
    return this._createUserFromResponse(res.data.data);
  }

  async Autocomplete(query: string): Promise<IUser[]> {
    const res = await this._client.get('/Mobile/User/Autocomplete', { params: { q: query } });
    
    const userList = res.data.data.map(
      (u:any) => ({ Id: u.id, FullName: u.fullName } as IUser)
    );

    return userList;
  }

  async Drivers_Autocomplete(query: string): Promise<any[]> {
    const res = await this._client.get('/HyrmaMobile/HyrmaUser/Drivers_Autocomplete', { params: { q: query } });
    
    const driversList = res.data.data.map(
      (u:any) => ({ Id: u.Id, Text: u.Text } as any)
    );

    return driversList;
  }

  _createUserFromResponse(response: any): User {
    const { authHash, userName, fullName, companyName, languageCode } = response;

    return new User({
      AuthHash: authHash,
      UserName: userName,
      FullName: fullName,
      CompanyName: companyName,
      LanguageCode: languageCode,
    });
  }
}