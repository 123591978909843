import { AxiosInstance } from 'axios';
import RentalObjectGroupList, { IRentalObjectGroupList_Item } from '@/modules/RentalObjectGroup/List';

export default class RentalObjectGroupApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(offset: number = 0, limit?: number): Promise<RentalObjectGroupList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/RentalObjectGroup/List',
      { params: { limit, offset } }
    );

    const items = res.data.data.items.map((g: any) => (
      {
        Id: g.id,
        Name: g.na,
      } as IRentalObjectGroupList_Item
    ));

    return new RentalObjectGroupList({
      Items: items,
      Count: res.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}