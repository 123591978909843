






import {
  Component,
  Vue
} from 'vue-property-decorator';
import User from './modules/User';

@Component
export default class App extends Vue {
  async created() {
   
  }
}
