import store from '@/store';

export interface IRentalTypeList {
  Items: Array<IRentalTypeList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface IRentalTypeList_Item {
  Id: number|null;
  Name: string;
  ForceIdenticalRentalDates: boolean;
  ForceRentedAmount: number|null;
}

export default class RentalTypeList implements IRentalTypeList {
  Items: Array<IRentalTypeList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: IRentalTypeList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number): Promise<RentalTypeList> {
    return await store.state.hyrma.client.RentalType.List((page - 1) * itemsPerPage, itemsPerPage);
  }

  static async FetchAll(): Promise<RentalTypeList> {
    return await store.state.hyrma.client.RentalType.List();
  }
}

