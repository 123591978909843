import { AxiosInstance } from 'axios';
import LeaseItemList, { ILeaseItemList_Item } from '@/modules/LeaseItem/List';
import LeaseItemEditRentalObject from '@/modules/LeaseItem/EditRentalObject';
import LeaseItemEditStockItem from '@/modules/LeaseItem/EditStockItem';
import LeaseItemEditTextItem from '@/modules/LeaseItem/EditTextItem';

export default class LeaseItemApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async EditRentalObject(id:number): Promise<LeaseItemEditRentalObject | null> {
    const res = await this._client.get(
      '/HyrmaMobile/LeaseItem/Edit_RentalObject',
      { params: { id } }
    );

    return new LeaseItemEditRentalObject(res.data.data);
  }

  async SaveRentalObject(li:LeaseItemEditRentalObject): Promise<LeaseItemEditRentalObject | null> {
    const res = await this._client.post(
      '/Mobile/LeaseItem/Edit_RentalObject',
      { ...li }
    );

    return new LeaseItemEditRentalObject(res.data.data);
  }

  async EditStockItem(id:number): Promise<LeaseItemEditStockItem | null> {
    const res = await this._client.get(
      '/HyrmaMobile/LeaseItem/Edit_StockItem',
      { params: { id } }
    );

    return new LeaseItemEditStockItem(res.data.data);
  }

  async SaveStockItem(li:LeaseItemEditStockItem): Promise<LeaseItemEditStockItem | null> {
    const res = await this._client.post(
      '/Mobile/LeaseItem/Edit_StockItem',
      { ...li }
    );

    return new LeaseItemEditStockItem(res.data.data);
  }

  async EditTextItem(id:number): Promise<LeaseItemEditTextItem | null> {
    const res = await this._client.get(
      '/HyrmaMobile/LeaseItem/Edit_TextItem',
      { params: { id } }
    );

    return new LeaseItemEditTextItem(res.data.data);
  }

  async SaveTextItem(li:LeaseItemEditTextItem): Promise<LeaseItemEditTextItem | null> {
    const res = await this._client.post(
      '/Mobile/LeaseItem/Edit_Text',
      { ...li }
    );

    return new LeaseItemEditStockItem(res.data.data);
  }

  async List(leaseId?: number, leaseNumber?:string): Promise<LeaseItemList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/LeaseItem/List',
      { params: { leaseId, leaseNumber } }
    );

    const items = res.data.data.items.map((li: any) => (
      {
        Id: li.id,
        Type: li.type,
        Number: li.number,
        InventoryItemNumber: li.inventoryItemNumber,
        Name: li.name,
        Quantity: li.quantity,
        Unit: li.unit,
        Date: li.date,
        RentalPeriod: li.rentalPeriod,
        UnitPrice: li.unitPrice,
        Discount: li.discount,
        NetAmount: li.netAmount,
        Vat: li.vat,
      } as ILeaseItemList_Item)
    );

    return new LeaseItemList({
      LeaseId: res.data.data.id,
      LeaseNumber: res.data.data.leaseNumber,
      Items: items,
    });
  }

  async Delete(id: number): Promise<void> {
    await this._client.post(
      '/Hyrma/LeaseItem/Delete' ,
      { id }
    );
  }
}