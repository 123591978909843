import { AxiosInstance } from 'axios';
import CustomerList, { ICustomerList_Item } from '@/modules/Customer/List';
import CustomerInfo from '@/modules/Customer/Info';

export default class CustomerApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async JsonInfo(customerId: number) : Promise<CustomerInfo | null> {
    const res = await this._client.get(
      '/Hyrma/Customer/JsonInfo',
      { params: { id: customerId } }
    );

    // TODO: Error handling

    const info = res.data.data;

    return new CustomerInfo({
      Id                            : info.id,
      CustomerAccount               : info.customerAccount,
      Address                       : info.address,
      Address2                      : info.address2,
      CountryCode                   : info.countryCode,
      Country                       : info.country,
      CurrencyCode                  : info.currencyCode,
      CustomerNumber                : info.customerNumber,
      CustomerType                  : info.customerType,
      CustomerReference             : info.customerReference,
      DeliveryAddress               : info.deliveryAddress,
      DeliveryAddress2              : info.deliveryAddress2,
      DeliveryName                  : info.deliveryName,
      DeliveryPostalArea            : info.deliveryPostalArea,
      DeliveryPostalCode            : info.deliveryPostalCode,
      DeliveryCountryCode           : info.deliveryCountryCode,
      DeliveryCountry               : info.deliveryCountry,
      EdiType                       : info.ediType,
      EdiTypeName                   : info.ediTypeName,
      IsEdiCustomer                 : info.isEdiCustomer,
      IsContractCustomer            : info.isContractCustomer,
      EmailAddress                  : info.emailAddress,
      InsurancePercent              : info.insurancePercent,
      RentalDiscountPercent         : info.rentalDiscountPercent,
      SalesDiscountPercent          : info.salesDiscountPercent,
      RentalBonusPercent            : info.rentalBonusPercent,
      RentalBonusCalculationType    : info.rentalBonusCalculationType,
      RentalBonusLadderStartAmount  : info.rentalBonusLadderStartAmount,
      RentalBonusLadderNumSteps     : info.rentalBonusLadderNumSteps,
      RentalBonusLadderStepAmount   : info.rentalBonusLadderStepAmount,
      RentalBonusLadderStepPercent  : info.rentalBonusLadderStepPercent,
      SalesBonusPercent             : info.salesBonusPercent,
      Name                          : info.name,
      OrgNo                         : info.orgNo,
      PaymentTerm                   : info.paymentTerm,
      PostalCode                    : info.postalCode,
      PostalArea                    : info.postalArea,
      Telephone                     : info.telephone,
      VatNo                         : info.vatNo,
      GlnNo                         : info.glnNo,
      CustomerReport                : info.customerReport,
      RentalReportEmail             : info.rentalReportEmail,
      IsBlocked                     : info.isBlocked,
      AutoShowReport                : info.autoShowReport,
      RequireLeaseCustomerAccount   : info.requireLeaseCustomerAccount,
      RequireLeaseCustomerReference : info.requireLeaseCustomerReference,
      RequireLeasePurchaser         : info.requireLeasePurchaser,
      CreditAmount                  : info.creditAmount,
      AccountsReceivable            : info.accountsReceivable,
      OverdueAmount                 : info.overdueAmount
    });
  }

  async GetRentalUnitPrice(currencyId: number, rentalTypeId?: number, rentalObjectId?: number, customerId?: number, customerProject?: number, rentedStart?: Date, rentedFrom?: string, leaseId?: number, rentedAmount?: number) : Promise<{ price: number, isStaggered: boolean }|null> {
    const res = await this._client.get(
      'Hyrma/Customer/GetRentalUnitPrice',
      { params: { currency: currencyId, rentalType: rentalTypeId, rentalObject: rentalObjectId, id: customerId, customerProject, rentedStart, rentedFrom, lease: leaseId, rentedAmount } }
    );
    
    const { price, isStaggered } = res.data.data;
    
    return { price, isStaggered };
  }

  async GetDiscountPercent(customerId: number, customerProjectId?: number, rentalObjectId?: number, rentalTypeId?:number) {
    const res = await this._client.get(
      '/Hyrma/Customer/GetRentalDiscountPercent',
      { params: { id: customerId, rentalObject: rentalObjectId, rentalType: rentalTypeId, customerProject: customerProjectId } }
    );
    
    return res.data.data.discountPercent;
  }

  async List(offset: number = 0, limit?: number, search?: string, sortBy?: string, sortAsc: boolean = true): Promise<CustomerList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/Customer/List',
      { params: { search, sortBy, sortAsc, limit, offset } }
    );

    // TODO: Error handling

    const items = res.data.data.map((d: any) => (
      {
        Id: d.id,
        Number: d.num,
        Name: d.nam,
        PostalArea: d.poa,
        OverdueAmount: d.oda,
      } as ICustomerList_Item)
    );

    return new CustomerList({
      Items: items,
      Count: res.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}