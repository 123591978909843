import moment from 'moment';
import store from '@/store';

export interface ILeaseList {
  Items: Array<ILeaseList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;

  DepotId?: number;
  SearchHead?: string;
  SearchContents?: string;
}

export interface ILeaseList_Item {
  LeaseId: number;
  LeaseNumber: string;
  CustomerNumber: string;
  CustomerName: string;
  CustomerType: string;
  CustomerAccount?: string;
  PostalArea: string;
  CreatedAt: string;
}

export default class LeaseList implements ILeaseList {
  Items: Array<ILeaseList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  DepotId?: number;
  SearchHead?: string;
  SearchContents?: string;

  constructor(obj?: ILeaseList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number, depotId?: number | null, searchHead?: string, searchContents?: string): Promise<LeaseList> {
    return await store.state.hyrma.client.Lease.List((page - 1) * itemsPerPage, itemsPerPage, depotId, searchHead, searchContents);
  }
}

