import { AxiosInstance } from 'axios';
import CountryList, { ICountryList_Item } from '@/modules/Country/List';

export default class CountryApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(): Promise<CountryList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/Country/List'
    );

    return new CountryList({
      CompanyCountryCode: res.data.data.companyCountryCode,
      Items: res.data.data.countries,
    });
  }
}