import store from '@/store';

export interface ITextItemList {
  Items: Array<ITextItemList_Item>;
  Count: number;
  Page: number;
  ItemsPerPage: number;
}

export interface ITextItemList_Item {
  Id: number;
  Code?: string;
  Text?: string;
}

export default class TextItemList implements ITextItemList {
  Items: Array<ITextItemList_Item> = [];
  Count: number = 0;
  Page: number = 1;
  ItemsPerPage: number = 10;

  constructor(obj?: ITextItemList) {
    Object.assign(this, obj);
  }

  static async Fetch(page: number, itemsPerPage: number, search?: string, sortAsc?:boolean): Promise<TextItemList> {
    return await store.state.hyrma.client.TextItem.List((page - 1) * itemsPerPage, itemsPerPage, search || undefined, sortAsc);
  }
}

