import { AxiosInstance } from 'axios';
import RentalTypeList, { IRentalTypeList_Item } from '@/modules/RentalType/List';

export default class RentalTypeApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(offset: number = 0, limit?: number): Promise<RentalTypeList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/RentalType/List',
      { params: { limit, offset } }
    );

    const items = res.data.data.items.map((rt: any) => (
      {
        Id: rt.id,
        Name: rt.na,
        ForceIdenticalRentalDates: rt.fd,
        ForceRentedAmount: rt.fa,
      } as IRentalTypeList_Item
    ));

    return new RentalTypeList({
      Items: items,
      Count: res.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}