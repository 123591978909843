import qs from 'qs';
import axios, { AxiosInstance } from 'axios';

import CalendarApi from './Hyrma/CalendarApi';
import CountryApi from './Hyrma/CountryApi';
import CurrencyApi from './Hyrma/CurrencyApi';
import CustomerApi from './Hyrma/CustomerApi';
import CustomerProjectApi from './Hyrma/CustomerProjectApi';
import DepotApi from './Hyrma/DepotApi';
import InventoryItemApi from './Hyrma/InventoryItemApi';
import LeaseApi from './Hyrma/LeaseApi';
import LeaseFileApi from './Hyrma/LeaseFileApi';
import LeaseItemApi from './Hyrma/LeaseItemApi';
import PaymentTermApi from './Hyrma/PaymentTermApi';
import RentalObjectGroupApi from './Hyrma/RentalObjectGroupApi';
import RentalTypeApi from './Hyrma/RentalTypeApi';
import StockItemApi from './Hyrma/StockItemApi';
import StockItemGroupApi from './Hyrma/StockItemGroupApi';
import TextItemApi from './Hyrma/TextItemApi';
import TranslationApi from './Hyrma/TranslationApi';
import UserApi from './Hyrma/UserApi';
import VatApi from './Hyrma/VatApi';

export class HyrmaApi {
  Calendar: CalendarApi;
  Country: CountryApi;
  Currency: CurrencyApi;
  Customer: CustomerApi;
  CustomerProject: CustomerProjectApi;
  Depot: DepotApi;
  InventoryItem: InventoryItemApi;
  Lease: LeaseApi;
  LeaseFile: LeaseFileApi;
  LeaseItem: LeaseItemApi;
  PaymentTerm: PaymentTermApi;
  RentalObjectGroup: RentalObjectGroupApi;
  RentalType: RentalTypeApi;
  StockItem: StockItemApi;
  StockItemGroup: StockItemGroupApi;
  TextItem: TextItemApi;
  Translation: TranslationApi;
  User: UserApi;
  Vat: VatApi;

  _client: AxiosInstance;

  constructor(baseURL: string) {
    this._client = axios.create({
      baseURL,
      paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
    });

    this.Calendar = new CalendarApi(this._client);
    this.Country = new CountryApi(this._client);
    this.Currency = new CurrencyApi(this._client);
    this.Customer = new CustomerApi(this._client);
    this.CustomerProject = new CustomerProjectApi(this._client);
    this.Depot = new DepotApi(this._client);
    this.InventoryItem = new InventoryItemApi(this._client);
    this.Lease = new LeaseApi(this._client);
    this.LeaseFile = new LeaseFileApi(this._client);
    this.LeaseItem = new LeaseItemApi(this._client);
    this.PaymentTerm = new PaymentTermApi(this._client);
    this.RentalObjectGroup = new RentalObjectGroupApi(this._client);
    this.RentalType = new RentalTypeApi(this._client);
    this.StockItem = new StockItemApi(this._client);
    this.StockItemGroup = new StockItemGroupApi(this._client);
    this.TextItem = new TextItemApi(this._client);
    this.Translation = new TranslationApi(this._client);
    this.User = new UserApi(this._client);
    this.Vat = new VatApi(this._client);
  }

  SetAuthorizationHeader(authHash: string): void {
    this._client.defaults.headers.common['Authorization'] = authHash;
  }
}

export default HyrmaApi;