


































import {
  Component,
  Vue
} from 'vue-property-decorator';
import User from '../modules/User';

@Component
export default class Login extends Vue {
  username = '';
  password = '';
  error = '';
  isBusy = false;

  async mounted() {
    this.isBusy = true;
    await this.$nextTick();
    const key = this.$route.query.QuickLoginKey;
    if (key !== undefined) {
      try {
        await User.QuickLogin(key + '');
        await this.$router.replace('/');
        return;
      } catch (_) {
        // invalid quicklogin key provided
      }
    }

    if (User.IsStored()) {
      try {
        await User.LoginStoredUser();
        await this.$router.replace('/');
        return;
      } catch (_) {
        // stored user is out of date
      }
    }

    this.isBusy = false;
  }

  async onSubmit() {
    this.isBusy = true;

    try {
      const user = await User.Login(this.username, this.password);
      await this.$router.replace('/');
    } catch (error) {
      this.error = error;
    }

    this.isBusy = false;
  }
}
