import store from '@/store';

export interface ILeaseItemList {
  LeaseId: number;
  LeaseNumber: string;
  Items: Array<ILeaseItemList_Item>;
}

export interface ILeaseItemList_Item {
  Id: number;
  Type: string;
  Number: string;
  InventoryItemNumber: string;
  Name: string;
  Quantity: string;
  Unit: string;
  Date: string;
  RentalPeriod: string;
  UnitPrice: string|null;
  Discount: string|null;
  NetAmount: string|null;
  Vat: string|null;
}

export default class LeaseItemList implements ILeaseItemList {
  LeaseId: number = -1;
  LeaseNumber: string = '';
  Items: Array<ILeaseItemList_Item> = [];
  
  constructor(obj?: ILeaseItemList) {
    Object.assign(this, obj);
  }

  static async Find(leaseId?: number, leaseNumber?: string): Promise<LeaseItemList> {
    return await store.state.hyrma.client.LeaseItem.List(leaseId, leaseNumber);
  }
  
  static async Delete(leaseItemId: number): Promise<void> {
    return await store.state.hyrma.client.LeaseItem.Delete(leaseItemId);
  }
}

