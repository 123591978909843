import store from '@/store';

export interface ILeaseFileList {
  LeaseId: number;
  Items: Array<ILeaseFileList_Item>;
}

export interface ILeaseFileList_Item {
  Id: number;
  Name: string;
  Url: string;
}

export default class LeaseFileList implements ILeaseFileList {
  LeaseId: number = -1;
  Items: Array<ILeaseFileList_Item> = [];
  
  constructor(obj?: ILeaseFileList) {
    Object.assign(this, obj);
  }

  static async Find(leaseId: number): Promise<LeaseFileList> {
    return await store.state.hyrma.client.LeaseFile.List(leaseId);
  }

  static async Upload(leaseId: number, file: File): Promise<void> {
    return await store.state.hyrma.client.LeaseFile.Upload(leaseId, file);
  }
  
  static async Delete(leaseFileId: number): Promise<void> {
    return await store.state.hyrma.client.LeaseFile.Delete(leaseFileId);
  }
}

