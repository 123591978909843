import { AxiosInstance } from 'axios';
import CustomerProjectList, { ICustomerProjectList_Item } from '@/modules/CustomerProject/List';
import CustomerProjectInfo from '@/modules/CustomerProject/Info';
import CustomerProjectEdit from '@/modules/CustomerProject/Edit';

export default class CustomerProjectApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async GetProjectLeases(customerId: number, projectNumber: string, leaseId?: number) : Promise<Array<string> | null> {
    const res = await this._client.get(
      '/Hyrma/CustomerProject/IsDuplicateProject',
      { params: { customer: customerId, projectNumber, lease: leaseId } }
    );

    return res.data.data.projectLeases;
  }

  async Autocomplete(search: string, customerId: number) : Promise<CustomerProjectInfo[] | null> {
    const res = await this._client.get(
      '/Hyrma/CustomerProject/Autocomplete',
      { params: { q: search, customer: customerId } }
    );

    // TODO: Proper error handling
    if (res.data.err)
      return null;

    return res.data.data.map((info: any) => {
      return new CustomerProjectInfo({
        Id                     : info.id,
        ProjectId              : info.projectId,
        ProjectNumber          : info.projectNumber,
        BaseProjectNumber      : info.baseProjectNumber,
        Name                   : info.name,
        Address                : info.address,
        Address2               : info.address2,
        PostalCode             : info.postalCode,
        PostalArea             : info.postalArea,
        CountryCode            : info.countryCode,
        Country                : info.country,
        ContactName            : info.contactName,
        Phone                  : info.phone,
        PriceList              : info.priceList,
        RentalInsurancePercent : info.rentalInsurancePercent,
        Comment                : info.comment,
        CustomerReference      : info.customerReference,
        CustomerId             : info.customerId,
      });
    });
  } 

  async JsonInfo(customerProjectNumber: string, customerId: number) : Promise<CustomerProjectInfo | null> {
    const res = await this._client.get(
      '/Hyrma/CustomerProject/JsonInfo',
      { params: { number: customerProjectNumber, customer: customerId } }
    );

    // TODO: Proper error handling
    if (res.data.err)
      return null;

    const info = res.data.data;

    return new CustomerProjectInfo({
      Id                     : info.id,
      ProjectId              : info.projectId,
      ProjectNumber          : info.projectNumber,
      BaseProjectNumber      : info.baseProjectNumber,
      Name                   : info.name,
      Address                : info.address,
      Address2               : info.address2,
      PostalCode             : info.postalCode,
      PostalArea             : info.postalArea,
      CountryCode            : info.countryCode,
      Country                : info.country,
      ContactName            : info.contactName,
      Phone                  : info.phone,
      PriceList              : info.priceList,
      RentalInsurancePercent : info.rentalInsurancePercent,
      Comment                : info.comment,
      CustomerReference      : info.customerReference,
      CustomerId             : info.customerId,
    });
  } 

  async SaveUpdate(customerProject: CustomerProjectEdit): Promise<CustomerProjectEdit | null> {
    var params = {
      customer: customerProject.CustomerId,
      projectNumber: customerProject.ProjectNumber,
      name: customerProject.Name,
      address: customerProject.Address,
      address2: customerProject.Address2,
      postalCode: customerProject.PostalCode,
      postalArea: customerProject.PostalArea,
      countryCode: customerProject.CountryCode,
      country: customerProject.Country,
      contactName: customerProject.ContactName,
      phone: customerProject.Phone,
    };

    const res = await this._client.post(
      '/Hyrma/CustomerProject/SaveUpdate',
      params
    );

    var projId = res.data.data.customerProjectId;

    return new CustomerProjectEdit(Object.assign({}, customerProject, { Id: projId }));
  }
 
  async List(customerId: string, offset: number = 0, limit?: number, search?: string, sortBy?: string, sortAsc: boolean = true): Promise<CustomerProjectList | null> {
    const res = await this._client.get(
      '/Hyrma/CustomerProject/QuickSelect_SlickGrid',
      { params: { customer: customerId, search, sortBy, sortAsc, limit, offset } }
    );

    // TODO: Error handling

    const items = res.data.data.map((cp: any) => (
      {
        Id             : cp.id,
        CustomerNumber : cp.cnu,
        CustomerName   : cp.cna,
        ProjectNumber  : cp.pro,
        ProjectName    : cp.nam,
        Address        : cp.ad1,
        Address2       : cp.ad2,
        PostalCode     : cp.pco, 
        PostalArea     : cp.par,
      } as ICustomerProjectList_Item)
    );

    return new CustomerProjectList({
      Items: items,
      Count: res.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}