import store from '@/store';

export interface ICurrencyList {
  CompanyCurrencyId: number;
  Items: Array<ICurrencyList_Item>;
}

export interface ICurrencyList_Item {
  Id: number;
  CurrencyCode: string;
  Rounding: number;
}

export default class CurrencyList {
  CompanyCurrencyId: number | null = null;
  Items: Array<ICurrencyList_Item> = [];

  constructor(obj?: ICurrencyList) {
    Object.assign(this, obj);
  }

  static async FetchAll(): Promise<CurrencyList> {
    return await store.state.hyrma.client.Currency.List();
  }
}

