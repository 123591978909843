import { AxiosInstance } from 'axios';
import Translation from '@/modules/Translation';

export default class TranslationApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async Find(languageCode: string): Promise<Translation | null> {
    const res = await this._client.get('/Hyrma/Translation/GetTranslations', { params: { languageCode } });

    // TODO: Error handling

    return new Translation(res.data.data);
  }
}