import store from '@/store';
import moment from 'moment';

export interface ILeaseItemEditTextItem {
  Id?: number;
  LeaseId: number;
  DiscountPercent: number;
  Quantity: number;
  Date: string;
  Name: string;
  UnitPrice: number;
  VatId: number;
  ReferenceUserId?: number | null;
  ReferenceUserText: string;
}

export default class LeaseItemEditTextItem implements ILeaseItemEditTextItem {
  Id?: number;
  LeaseId: number = -1;
  DiscountPercent: number = 0;
  Quantity: number = 1;
  Date: string = moment().format('YYYY-MM-DD');
  Name: string = '';
  UnitPrice: number = 0;
  VatId: number = -1;
  ReferenceUserId?: number | null = null;
  ReferenceUserText: string = '';
  
  constructor(obj?: ILeaseItemEditTextItem) {
    Object.assign(this, obj);
  }

  static async Find(id: number): Promise<LeaseItemEditTextItem> {
    return await store.state.hyrma.client.LeaseItem.EditTextItem(id);
  }

  async Save(): Promise<LeaseItemEditTextItem> {
    const leaseItem = await store.state.hyrma.client.LeaseItem.SaveTextItem(this);
    Object.assign(this, leaseItem);
    return this;
  }
}

