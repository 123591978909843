import { AxiosInstance } from 'axios';
import LeaseFileList, { ILeaseFileList_Item } from '@/modules/LeaseFile/List';

export default class LeaseFileApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async Upload(leaseId:number, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('lease', `${leaseId}`);
    formData.append('file', file);
    
    const res = await this._client.post(
      '/HyrmaMobile/LeaseFile/Upload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  async List(leaseId: number): Promise<LeaseFileList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/LeaseFile/JsonInfo',
      { 
        params: { 
          lease: leaseId 
        } 
      }
    );

    const items = res.data.data.Files.map((lf: any) => (
      {
        Id: lf.Id,
        Name: lf.Name,
        Url: lf.Url,
      } as ILeaseFileList_Item)
    );

    return new LeaseFileList({
      LeaseId: leaseId,
      Items: items,
    });
  }

  async Delete(id: number): Promise<void> {
    await this._client.post(
      '/HyrmaMobile/LeaseFile/Delete',
      { id }
    );
  }
}