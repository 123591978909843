import { AxiosInstance } from 'axios';
import StockItemGroupList, { IStockItemGroupList_Item } from '@/modules/StockItemGroup/List';

export default class StockItemGroupApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(offset: number = 0, limit?: number): Promise<StockItemGroupList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/StockItemGroup/List',
      { params: { limit, offset } }
    );

    const items = res.data.data.items.map((g: any) => (
      {
        Id: g.id,
        Name: g.na,
      } as IStockItemGroupList_Item
    ));

    return new StockItemGroupList({
      Items: items,
      Count: res.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}