import store from '@/store';

export interface ICustomerProjectInfo {
  Id                     : number,
  ProjectId              : number,
  ProjectNumber          : string,
  BaseProjectNumber      : string,
  Name                   : string,
  Address                : string,
  Address2               : string,
  PostalCode             : string,
  PostalArea             : string,
  CountryCode            : string,
  Country                : string,
  ContactName            : string,
  Phone                  : string,
  PriceList              : number,
  RentalInsurancePercent : number,
  Comment                : string,
  CustomerReference      : string,
  CustomerId             : number,
}

export default class CustomerProjectInfo implements ICustomerProjectInfo {
  Id                     : number = 0;
  ProjectId              : number = 0;
  ProjectNumber          : string = '';
  BaseProjectNumber      : string = '';
  Name                   : string = '';
  Address                : string = '';
  Address2               : string = '';
  PostalCode             : string = '';
  PostalArea             : string = '';
  CountryCode            : string = '';
  Country                : string = '';
  ContactName            : string = '';
  Phone                  : string = '';
  PriceList              : number = 0;
  RentalInsurancePercent : number = 0;
  Comment                : string = '';
  CustomerReference      : string = '';
  CustomerId             : number = 0;

  constructor(obj?: CustomerProjectInfo) {
    Object.assign(this, obj);
  }

  static async Find(customerProjectNumber: string, customerId: number): Promise<CustomerProjectInfo | null> {
    return await store.state.hyrma.client.CustomerProject.JsonInfo(customerProjectNumber, customerId);
  }

  static async Autocomplete(search: string, customerId: number): Promise<CustomerProjectInfo[] | null> {
    return await store.state.hyrma.client.CustomerProject.Autocomplete(search, customerId);
  }
}
