import { AxiosInstance } from 'axios';
import StockItemList, { IStockItemList_Item } from '@/modules/StockItem/List';

export default class StockItemApi {
  _client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this._client = client;
  }

  async List(offset: number = 0, limit?: number, search?: string, depot?: number, group?: number): Promise<StockItemList | null> {
    const res = await this._client.get(
      '/HyrmaMobile/StockItem/List',
      { params: { q: search, depot, group, limit, offset } }
    );

    const items = res.data.data.items.map((si: any) => (
      {
        Id: si.id,
        ItemNumber: si.inu,
        Name: si.nam,
        Group: si.grp,
        Unit: si.uni,
        UnitPrice: si.pri,
        DefaultVatId: si.vat,
      } as IStockItemList_Item)
    );

    return new StockItemList({
      Items: items,
      Count: res.data.data.count,
      Page: limit ? Math.floor((offset / limit)) + 1 : 1,
      ItemsPerPage: limit || res.data.count,
    });
  }
}