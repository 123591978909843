import store from '@/store';
import Translation from '@/modules/Translation';
import { ICountryList_Item } from '@/modules/Country/List';
import UserRole from '@/enums/UserRole';

export interface IUser {
  Id?: number;
  AuthHash: string;
  FullName: string;
  UserName: string;
  LanguageCode: string;
  CompanyName: string;
}

export interface IUser_Depot {
  Id: number;
  ShortName: string;
  Name: string;
}

export default class User implements IUser {
  AuthHash: string = '';
  FullName: string = '';
  UserName: string = '';
  LanguageCode: string = '';
  CompanyName: string = '';

  constructor(obj?: IUser) {
    Object.assign(this, obj);
  }

  static async QuickLogin(key: string): Promise<void> {
    const user = await store.state.hyrma.client.User.QuickLogin(key);
    await this.StoreUser(user);
  }

  static async Login(username: string, password: string): Promise<void> {
    const user = await store.state.hyrma.client.User.Login(username, password);
    await this.StoreUser(user);
  }

  static async Logout() {
    localStorage.removeItem('user');
    await store.dispatch('setHyrmaUser', null);
  }

  static async StoreUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    await store.dispatch('setHyrmaUser', user);
    await Translation.Load(user.LanguageCode);
  }

  static async LoginStoredUser() {
    const storedUser = new User(JSON.parse(localStorage.getItem('user') + ''));
    store.state.hyrma.client.SetAuthorizationHeader(storedUser.AuthHash);
    try {
      const userInfo = await store.state.hyrma.client.User.Info();
      await this.StoreUser(userInfo);
    } catch(_) {
      localStorage.removeItem('user');
      await store.dispatch('setHyrmaUser', null);
      throw 'Invalid credentials';
    }
  }

  static IsLoggedIn(): boolean {
    return store.state.hyrma.user !== null;
  }

  static IsStored(): boolean {
    const user = new User(JSON.parse(localStorage.getItem('user') + ''));
    return user.AuthHash !== '';
  }

  static async Can(feature: string): Promise<boolean> {
    return await store.state.hyrma.client.User.Can(feature);
  }

  static async Depot(): Promise<IUser_Depot|null> {
    return await store.state.hyrma.client.User.Depot();
  }

  static async CompanyCountry(): Promise<ICountryList_Item> {
    return await store.state.hyrma.client.User.CompanyCountry();
  }

  static async Autocomplete(query: string): Promise<any> {
    return await store.state.hyrma.client.User.Autocomplete(query);
  }

  static async Drivers_Autocomplete(query: string): Promise<any> {
    return await store.state.hyrma.client.User.Drivers_Autocomplete(query);
  }

  static async Role(): Promise<UserRole|null> {
    return await store.state.hyrma.client.User.Role();
  }
}
